import { render, staticRenderFns } from "./CountryVisaSort.vue?vue&type=template&id=858f8372&"
import script from "./CountryVisaSort.vue?vue&type=script&lang=js&"
export * from "./CountryVisaSort.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports