<template>
    <div>
        <draggable 
            v-model="visas" 
            group="customFields" 
            @start="drag=true"
            @end="drag=false"
            ghost-class="ghost"
        >
        <transition-group>
            <div v-for="(val, index) in visas" :key="index">
                <div class="w-100 py-1 ml-1">
                    <h5>{{val.country.name_uz}}</h5>
                </div>
            </div>
        </transition-group>
        </draggable>
        <div class="mt-1 d-flex justify-content-end">
            <b-button variant="primary" @click="handleSave">{{$t('message.Save')}}</b-button>
        </div>
    </div>
</template>
<script>
    import {
      getCountryVisas,
      sortCountryVisas,
    } from '@/api/countries/country-visa'
    import draggable from "vuedraggable";


export default {
    name: 'CountryVisaSort',
    components: {
        draggable,
    },
    data() {
        return {
            visas: []
        }
    },
    mounted() {
        this.fetchCountryVisas()
    },
    methods: {
        fetchCountryVisas() {
            getCountryVisas({all: true}).then(res => {
                this.visas = res.data.data
            })
        },
        handleSave() {
            let count = 1;
            let d = [];
            this.visas.forEach(item => {
                item.sort = count++
                d.push({id: item.id, sort: count++});
            })
            sortCountryVisas({data: d}).then(() => {
                this.$emit('handleSortClose')
            })
        }
    }
}
</script>
<style lang="">
    
</style>