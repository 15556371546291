<template>
    <div>
      <b-overlay :show="loading">
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="info" class="mb-1 mr-1" @click="handleSortModal=true">
          Sort
        </b-button>
        <b-button variant="primary" class="mb-1" @click="handleAdd">
          <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
        </b-button>
      </div>
      <b-card>
        <b-table :fields="fields" :items="countryVisas" responsive :busy="busy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
          </template>
          <template #cell(flag_img)="data">
            <div v-if="data.item.flag_img">
              <img :src="getBackendUrl+data.item.flag_img" width="50"alt="">
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <div class="d-flex">
                <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)" class="mr-1">
                  <b-icon icon="trash"></b-icon>
                </b-button>
                <b-button variant="outline-info" size="sm" @click="$router.push({name: 'CountryVisasDetail', params: {id: data.item.id}})" class="mr-1">
                  <b-icon icon="eye"></b-icon>
                </b-button>
                <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                  <b-icon icon="pencil"></b-icon>
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </b-card>
  
      <b-pagination
        v-model="pagination.page"
        :total-rows="total"
        :per-page="pagination.pageSize"
        @input="fetchCountryVisas"
      ></b-pagination>
      </b-overlay>
      <b-modal hide-footer v-model="formModalActive" no-close-on-backdrop>
        <CountryVisaForm :id="id" :countries="countries" @closeModal="closeModal" />
      </b-modal>
      <b-modal hide-footer no-close-on-backdrop v-model="handleSortModal" >
        <CountryVisaSort @handleSortClose="handleSortClose" />
      </b-modal>
    </div>
  </template>
  
  <script>
    import Swal from 'sweetalert2'
    import ExpenseForm from '@/views/directories/expense-type/components/ExpenseForm'
    import {
      getCountryVisas,
      deleteCountryVisa
    } from '@/api/countries/country-visa'
    import {
    getCountries
  } from '@/api/countries/countries'
    import CountryVisaForm from '@/views/directories/country-visas/components/CountryVisaForm'
    import CountryVisaSort from '@/views/directories/country-visas/components/CountryVisaSort'
    import AppConfig from '@/libs/vue-axios.config';
  
    export default {
      name: 'Index',
      components: {
        CountryVisaForm,
        ExpenseForm,
        CountryVisaSort,
      },
      data() {
        return {
          id: null,
          countries: [],
          fields: [{
              key: 'id',
              label: this.$t('ID')
            },
            // {
            //   key: 'name_uz',
            //   label: this.$t('message.Name_uz')
            // },
            {
              key: 'country.name_uz',
              label: this.$t('message.Country1')
            },
            {
              key: 'label',
              label: this.$t('Label'),
            },
            {
              key: 'title',
              label: this.$t('message.Title'),
            },
            {
              key: 'price',
              label: this.$t('message.Price'),
            },
            {
              key: 'fee',
              label: this.$t('message.Fee'),
            },
            {
              key: 'currency.currency',
              label: this.$t('message.Currency'),
            },
            {
              key: 'vat.percentage',
              label: this.$t('VAT'),
            },
            // {
            //   key: 'name_en',
            //   label: this.$t('message.Name_en')
            // },
            {
              key: 'action',
              label: this.$t('message.Action')
            },
          ],
          formModalActive: false,
          pagination: {
            page: 1,
            pageSize: 20
          },
          total: 1,
          busy: true,
          loading: false,
          countryVisas: [],
          handleSortModal: false,
        }
      },
      created() {
        this.fetchCountryVisas()
        this.fetchCountries()
      },
      computed: {
        getBackendUrl() {
          return AppConfig.backendPath
        }
      },
      methods: {
        fetchCountryVisas() {
          this.loading = true;
          getCountryVisas(this.pagination).then(response => {
            this.countryVisas = response.data.data.data
            this.total = response.data.data.total;
            this.busy = false;
            this.loading = false;
          })
        },
        fetchCountries () {
            getCountries({all: true,}).then(res => {
                this.countries = res.data.data
            })
        },
        handleAdd() {
          this.id = null;
          this.formModalActive = true
        },
        handleEdit(id) {
          this.id = id;
          this.formModalActive = true
        },
        closeModal() {
          this.formModalActive = false;
          this.fetchCountryVisas()
        },
        handleDelete(id) {
          if (id) {
            Swal.fire({
              title: this.$t('areYouSure'),
              text: this.$t('wontReturn'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: this.$t('YesDelete'),
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                // eslint-disable-next-line no-unused-vars
                deleteCountryVisa(id).then(res => {
                  Swal.fire({
                    icon: 'success',
                    position: 'center',
                    title: this.$t('Deleted'),
                    text: this.$t('dataHasDeleted'),
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  this.fetchCountryVisas()
                })
              } else if (result.dismiss === 'cancel') {
                Swal.fire({
                  title: this.$t('Cancelled'),
                  text: this.$t('yrDataSafe'),
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
          }
        },
        handleSortClose() {
          this.handleSortModal = false
          this.fetchCountryVisas()
        }
      }
    }
  </script>
  
  <style scoped>
  
  </style>