<template>
    <div>
      <b-overlay :show="loading">
        <validation-observer ref="refFormObserver">
            <validation-provider #default="validationContext" name="Country" rules="required">
                <b-form-group :label="$t('SelectCountry')">
                    <v-select :options="countries" label="name_uz" v-model="selectedCountry">
                    <template #option="{ name_uz, flag_img }">
                        <div class="d-flex items-center">
                            <div v-if="flag_img">
                                <img :src="getBackendUrl+flag_img" width="30" alt="">
                            </div>
                            <div class="ml-1">
                                <p>{{ name_uz }}</p>
                            </div>
                        </div>
                    </template>
                    <template #selected-option="{ name_uz, flag_img }">
                        <div class="d-flex items-center">
                            <div v-if="flag_img">
                                <img :src="getBackendUrl+flag_img" width="30" alt="">
                            </div>
                            <div class="ml-1">
                                <p>{{ name_uz }}</p>
                            </div>
                        </div>
                    </template>
                </v-select>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
            </b-form-group>
            </validation-provider>
          <validation-provider #default="validationContext" name="Visa Category" rules="required">
            <b-form-group :label="$t('VisaCategory')" for="visacategory" class="mt-2">
              <b-form-input v-model="visaForm.type"></b-form-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Label" rules="required">
            <b-form-group :label="$t('Label')" for="label" class="mt-2">
              <b-form-input v-model="visaForm.label"></b-form-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Title" rules="required">
            <b-form-group :label="$t('message.title')" for="title">
              <b-form-input v-model="visaForm.title"></b-form-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
  
          <validation-provider #default="validationContext" name="Description" rules="required">
            <b-form-group :label="$t('Description')" for="description">
              <b-form-textarea v-model="visaForm.description"></b-form-textarea>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Currency" rules="required">
            <b-form-group :label="$t('message.Currency')" for="currency">
              <v-select :options="currencies" label="currency" v-model="selectedCurrency"></v-select>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="From Price" rules="required">
            <b-form-group :label="$t('message.Price')" for="price">
              <b-form-input v-model="visaForm.price" type="number"></b-form-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Fee" rules="required">
            <b-form-group :label="$t('message.Fee')" for="fee">
              <b-form-input v-model="visaForm.fee" type="number"></b-form-input>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="VAT" rules="required">
            <b-form-group :label="$t('VAT')" for="vat">
              <v-select :options="vats" label="title" v-model="selectedVat"></v-select>
              <span class="text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
  
          <div class="w-100 d-flex justify-content-end mt-3">
            <b-button variant="warning" class="mr-1" @click="$emit('closeModal')">
              {{$t('message.Cancel')}}
            </b-button>
            <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
              {{$t('message.Save')}}
            </b-button>
          </div>
  
        </validation-observer>
      </b-overlay>
    </div>
  </template>
  
  <script>
    import {
      ValidationProvider,
      ValidationObserver
    } from 'vee-validate'
    import {
      required,
      alphaNum
    } from '@validations'
    import {
      getCountriesById
    } from '@/api/countries/countries'
    import vSelect from 'vue-select'
    import AppConfig from '@/libs/vue-axios.config';
    import {
        storeNewCountryVisa,
        showCountryVisa,
        editCountryVisa
    } from '@/api/countries/country-visa'
    import {getCurrencies, getVats} from '@/api/currency/index'
  
    export default {
      name: 'CountryForm',
      components: {
        ValidationProvider,
        ValidationObserver,
        vSelect,
      },
      props: {
        id: {
          default: null
        },
        countries: {
            type: Array,
        }
      },
      data() {
        return {
          required,
          alphaNum,
          selectedCountry: null,
          visaForm: {
            id: null,
            country_id: '',
            label: '',
            title: '',
            description: null,
            price: null,
            fee: null,
            currency_id: null,
            total: null,
            vat_id: null,
            type: '',
          },
          disabled: false,
          loading: false,
          currencies: [],
          selectedCurrency: null,
          selectedVat: null,
          vats: [],
        }
      },
      computed: {
        getBackendUrl() {
          return AppConfig.backendPath
        }
      },
      created() {
        this.fetchCurrencies()
        this.fetchVats()
      },
      methods: {
        handleSubmit() {
          const isValidate = this.$refs.refFormObserver.validate();
          if (isValidate) {
            this.disabled = true;
            this.loading = true;
            setTimeout(() => {
              this.disabled = false;
              this.loading = false;
            }, 1000)
            this.visaForm.country_id = this.selectedCountry.id
            this.visaForm.currency_id = this.selectedCurrency.id
            this.visaForm.total = new Number(this.visaForm.price) + new Number(this.visaForm.fee)
            this.visaForm.vat_id = this.selectedVat ? this.selectedVat.id : null;
            if (this.id) {
              editCountryVisa(this.visaForm, this.id).then(res => {
                this.$emit('closeModal')
              })
            } else {
              storeNewCountryVisa(this.visaForm).then(res => {
                this.$emit('closeModal')
              })
            }
            
          }
        },
        fetchCountryById() {
          showCountryVisa(this.id).then(res => {
            this.visaForm = res.data.data;
            this.selectedCountry = res.data.data.country
            this.selectedCurrency = res.data.data.currency
            this.selectedVat = res.data.data.vat
          })
        },
        handlePhotoUpload(e) {
          this.countries.file = e.target.files[0]
        },
        fetchCurrencies() {
          getCurrencies().then(res => {
            this.currencies = res.data.data
          })
        },
        fetchVats() {
          getVats().then(res => {
            this.vats = res.data.data;
          })
        },
      },
      watch: {
        id: {
          immediate: true,
          handler(val) {
            if (val) {
              this.fetchCountryById()
            }
          }
        }
      }
    }
  </script>
  
  <style scoped>
  
  </style>